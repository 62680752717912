import { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { closeIcon } from "../../../assets";

import { ERoutesPath } from "../../../routes";
import { useNavigate } from "react-router-dom-v5-compat";
import { AuthContext } from "../../../contexts/auth";
import api, { END_POINTS } from "../../../services/api";
import { TSimulate } from "../../Simulate/types";

import {
  Button,
  Flex,
  PageTemplate,
  Question,
  ReviseLoading,
  Typography,
} from "../../../components";

import { formatDateDay } from "../../../utils/date";
import { TQuestion } from "../../../contexts/question";

import { AdminAssignSimulate } from "./AssignSimulate";

import { EStep } from "./types";
import * as S from "./styles";

export const AdminSimulatePage = () => {
  const [simulates, setSimulates] = useState<Array<TSimulate>>([]);
  const [selectedSimulates, setSelectedSimulates] = useState<TSimulate>();
  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showQuestion, setShowQuestion] = useState<boolean>(false);
  const [simulateStep, setSimulateStep] = useState<EStep>(EStep.LIST);

  const navigate = useNavigate();

  const { typeUser, user } = useContext(AuthContext);
  const idAccount = user.id_user;

  const admSimulateBreadcrumb = [
    { label: "Painel administrativo", route: ERoutesPath.INICIO_PROFISSIONAL },
    { label: "Simulados" },
  ];

  useEffect(() => {
    if (typeUser !== "10") navigate(ERoutesPath.ROOT);

    const getSimulates = async () => {
      try {
        setSelectedSimulates(undefined);
        setShowQuestion(false);
        setIsLoading(true);
        const simulateData = await api.get<Array<TSimulate>>(
          `${END_POINTS.simulates}/${idAccount}`
        );

        setSimulates(simulateData.data);
      } finally {
        setIsLoading(false);
      }
    };

    getSimulates();
  }, []);

  const handleSeeSimulates = async (simulateData: TSimulate) => {
    const simulateQuestions = await api.get<Array<TQuestion>>(
      `${END_POINTS.simulateQuestions}/${simulateData.id}`
    );
    setSelectedSimulates(simulateData);

    setQuestions(Object.values(simulateQuestions.data));
    setShowQuestion(true);
  };

  const handleAssign = (step: EStep) => {
    setSimulateStep(step);
  };

  return (
    <>
      {simulateStep === EStep.LIST ? (
        <PageTemplate
          title="Administração - simulados"
          breadcrumbsItems={admSimulateBreadcrumb}
        >
          <Flex direction="column" width="100%" gap={16}>
            <Button
              isSecondary
              size="small"
              onClick={() => navigate(ERoutesPath.ADMINISTRAR_SIMULADOS_NOVO)}
            >
              Criar novo simulado
            </Button>

            {isLoading ? (
              <ReviseLoading />
            ) : (
              <>
                {simulates.map((simulate) => (
                  <S.ListItem
                    key={simulate.id}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex gap={16} alignItems="center">
                      <Flex direction="column">
                        <Typography size={10} color="#6e6893">
                          Criado em
                        </Typography>
                        <Typography size={14} color="#607D8B">
                          {formatDateDay(simulate.created_at)}
                        </Typography>
                      </Flex>

                      <Flex direction="column">
                        <Typography size={10} color="#6e6893">
                          Nome simulado
                        </Typography>
                        <Typography size={14} weight={600}>
                          {simulate.name}
                        </Typography>
                      </Flex>
                    </Flex>

                    <Flex direction="row" alignItems="center" gap={8}>
                      <Button
                        onClick={() => handleSeeSimulates(simulate)}
                        size="small"
                        isLoading={false}
                      >
                        Ver questões
                      </Button>
                    </Flex>
                  </S.ListItem>
                ))}
              </>
            )}
          </Flex>

          <Drawer
            open={showQuestion}
            onClose={() => setShowQuestion(false)}
            anchor="right"
          >
            {selectedSimulates && (
              <Flex
                justifyContent="space-between"
                gap={16}
                style={{ padding: "16px" }}
              >
                <Flex gap={8} alignItems="center" style={{ flexWrap: "wrap" }}>
                  <Typography size={16} color="#607D8B">
                    {formatDateDay(selectedSimulates.created_at)}
                  </Typography>
                  |
                  <Typography size={20} weight={600}>
                    {selectedSimulates.name}
                  </Typography>
                  |
                  <Typography size={16}>
                    {questions && questions.length} questões
                  </Typography>
                  |
                  <Button
                    size="small"
                    onClick={() => {
                      handleAssign(EStep.ASSIGN);
                      setShowQuestion(false);
                    }}
                  >
                    Atribuir
                  </Button>
                </Flex>

                <img
                  src={closeIcon}
                  onClick={() => setShowQuestion(false)}
                  style={{ cursor: "pointer" }}
                />
              </Flex>
            )}

            <Flex
              direction="column"
              gap={16}
              width="90vw"
              style={{ padding: "16px", backgroundColor: "#f9f9f9" }}
            >
              {questions &&
                questions.map((question, key) => (
                  <Question
                    data={question as TQuestion}
                    key={question.id}
                    id={question.id}
                    number={key + 1}
                    data-id={question.id}
                    hideAction
                    hideAlternatives
                  />
                ))}
            </Flex>
          </Drawer>
        </PageTemplate>
      ) : (
        <AdminAssignSimulate
          simulateData={selectedSimulates}
          questionsData={questions?.map((question) => question.id)}
          changeStep={() => {
            setSimulateStep(EStep.LIST);
            setSelectedSimulates(undefined);
            setQuestions(undefined);
          }}
        />
      )}
    </>
  );
};
