import { Flex } from '../../../components';
import { TCheckboxProps } from './types';
import * as S from './styles';

export const Checkbox = ({ name, checked, label, onChange, ...rest }: TCheckboxProps) => (
  <Flex alignItems='center' style={{cursor: 'pointer'}}>
    <S.ContainerCheckbox
      name={name}
      id={name}
      checked={checked}
      type="checkbox"
      onChange={onChange}
      {...rest}
    />
    {label && <S.Label htmlFor={name}>{label}</S.Label>}
  </Flex>
);