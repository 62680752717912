import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  InputText,
  MultipleSelect,
  PageTemplate,
  ReviseLoading,
  Typography,
  Pagination,
} from "../../../../components";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom-v5-compat";

import api, { END_POINTS } from "../../../../services/api";
import { toast } from "react-toastify";

import { ERoutesPath } from "../../../../routes";
import { TQuestion } from "../../../../contexts/question";
import { AuthContext } from "../../../../contexts/auth";
import { QuestionImage } from "../../../../components/organisms/Question/helpers";
import {
  capitalizeWords,
  normalizeString,
  sortArray,
} from "../../../../helpers";
import { timeOption } from "../../../../components/organisms/QuestionFilter/helper";
import { TFilterOptions } from "../../../../components/organisms/QuestionFilter/types";

import { TDataForm, TFilterOptionsSimulate } from "./types";
import * as S from "./styles";

const deduplicateArray = (arr: string[]): string[] => {
  const seen = new Map<string, string>();
  arr.forEach((item) => {
    const normalized = normalizeString(item);
    if (!seen.has(normalized)) {
      seen.set(normalized, item); // Store first occurrence
    }
  });
  return Array.from(seen.values());
};

export const AdminCreateSimulatePage = ({}) => {
  const { typeUser, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [questionsToShow, setQuestionsToShow] = useState<Array<TQuestion>>();
  const [offset, setOffset] = useState<number>(0);

  const [filterOptions, setFilterOptions] = useState<TFilterOptionsSimulate>();

  const idAccount = user.id_user;
  const ITEMS_PER_PAGE = 20;

  const admSimulateAssignBreadcrumb = [
    { label: "Painel administrativo", route: ERoutesPath.INICIO_PROFISSIONAL },
    {
      label: "Simulados",
      route: ERoutesPath.ADMINISTRAR_SIMULADOS,
    },
    {
      label: "Criar",
    },
  ];

  const INITIAL_VALUES: TDataForm = {
    selectedQuestions: [] as number[],
    time: "",
    name: "",
    courses: [],
    matters: [],
    banks: [],
    years: [],
    difficulties: [],
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => handleSubmit(data),
  });

  useEffect(() => {
    if (typeUser !== "10") navigate(ERoutesPath.ROOT);

    const handleQuestions = async () => {
      try {
        setQuestions(undefined);
        setIsLoading(true);
        const filter = await api.get<TFilterOptions>(
          END_POINTS.filterQuestions
        );
        const filterData = filter.data;

        const questionsData = await api.post<Array<TQuestion>>(
          `${END_POINTS.questionByFilter}`,
          { id_account: idAccount }
        );
        const questions = questionsData.data;

        setFilterOptions({
          courses: deduplicateArray(
            Object.keys(filterData.banks).reduce<string[]>((acc, bank) => {
              Object.keys(filterData.banks[bank]).forEach((course) => {
                if (!acc.includes(course)) acc.push(course);
              });
              return acc;
            }, [])
          ),
          matters: deduplicateArray(
            Object.keys(filterData.banks).reduce<string[]>((acc, bank) => {
              Object.values(filterData.banks[bank]).forEach((matters) => {
                Object.values(matters)
                  .flat()
                  .forEach((matter) => {
                    if (!acc.includes(matter)) acc.push(matter);
                  });
              });
              return acc;
            }, [])
          ),
          banks: deduplicateArray(Object.keys(filterData.banks)),
          years: deduplicateArray(filterData.years),
          difficulties: deduplicateArray(filterData.difficulties),
        });

        setQuestions(questions);
      } catch {
        toast.error("Falha ao buscar questões");
      } finally {
        setIsLoading(false);
      }
    };

    handleQuestions();
  }, []);

  const handleSubmit = async (dataForm: TDataForm) => {
    try {
      const body = {
        name: dataForm.name,
        ids_accounts: [idAccount],
        time: dataForm.time,
        ids_questions: dataForm.selectedQuestions,
      };

      await api.post(END_POINTS.createBatchSimulates, body);
      toast.success("Simulado criado com sucesso");

      navigate(ERoutesPath.ADMINISTRAR_SIMULADOS);
    } catch (error) {
      toast.error(`Falha ao criar simulado - ${error.message}`);
    } finally {
      formik.setFieldValue("selectedQuestions", []);
    }
  };

  const handleCheckboxChange = (questionId: number) => {
    const { selectedQuestions } = formik.values;
    if (selectedQuestions.includes(questionId)) {
      formik.setFieldValue(
        "selectedQuestions",
        selectedQuestions.filter((id) => id !== questionId)
      );
    } else {
      formik.setFieldValue("selectedQuestions", [
        ...selectedQuestions,
        questionId,
      ]);
    }
  };

  const filteredQuestions = useMemo(() => {
    return questions?.filter((question) => {
      const normalizedCourse = normalizeString(question.course);
      const normalizedMatter = normalizeString(question.matter);
      const normalizedBank = normalizeString(question.bank);
      const normalizedDifficulty = normalizeString(question.difficulty);

      return (
        (formik.values.courses.length === 0 ||
          formik.values.courses.some(
            (course) => normalizeString(course) === normalizedCourse
          )) &&
        (formik.values.matters.length === 0 ||
          formik.values.matters.some(
            (matter) => normalizeString(matter) === normalizedMatter
          )) &&
        (formik.values.banks.length === 0 ||
          formik.values.banks.some(
            (bank) => normalizeString(bank) === normalizedBank
          )) &&
        (formik.values.years.length === 0 ||
          formik.values.years.includes(String(question.year))) &&
        (formik.values.difficulties.length === 0 ||
          formik.values.difficulties.some(
            (difficulty) => normalizeString(difficulty) === normalizedDifficulty
          ))
      );
    });
  }, [questions, formik.values]);

  useEffect(() => {
    setQuestionsToShow(
      filteredQuestions &&
        filteredQuestions.slice(offset, offset + ITEMS_PER_PAGE)
    );
  }, [filteredQuestions, offset]);

  const disableForm = () =>
    formik.values.selectedQuestions.length < 15 ||
    !formik.values.name ||
    !formik.values.time;

  return (
    <PageTemplate
      title="Administração - novo simulado"
      breadcrumbsItems={admSimulateAssignBreadcrumb}
    >
      <Flex direction="column" width="100%" gap={16}>
        {isLoading ? (
          <ReviseLoading />
        ) : (
          <>
            <S.Container>
              <S.Form onSubmit={formik.handleSubmit} noValidate>
                <Flex gap={16} width="100%">
                  <InputText
                    label="Nome do simulado"
                    name="name"
                    formik={formik}
                    minLength={3}
                    maxLength={30}
                  />
                  <MultipleSelect
                    label="Tempo"
                    name="time"
                    options={timeOption}
                    formik={formik}
                    acceptOne
                  />
                </Flex>

                <Flex justifyContent="space-between">
                  <Typography size={14} color="#607D8B">
                    {formik.values.selectedQuestions.length} questões
                    selecionadas
                  </Typography>
                  <Button size="small" disabled={disableForm()}>
                    Criar simulado
                  </Button>
                </Flex>

                <Flex direction="column" gap={8}>
                  <Typography size={14} weight={600}>
                    Filtrar Questões
                  </Typography>

                  {filterOptions && (
                    <>
                      <Flex direction="column" gap={16}>
                        <Flex gap={16}>
                          <MultipleSelect
                            label="Disciplina"
                            name="courses"
                            options={sortArray(filterOptions.courses)}
                            formik={formik}
                          />

                          <MultipleSelect
                            label="Assunto"
                            name="matters"
                            options={sortArray(filterOptions.matters)}
                            formik={formik}
                          />
                        </Flex>

                        <Flex gap={16}>
                          <MultipleSelect
                            label="Banca"
                            name="banks"
                            options={sortArray(filterOptions.banks)}
                            formik={formik}
                            upperCase
                          />

                          <MultipleSelect
                            label="Ano"
                            name="years"
                            options={sortArray(filterOptions.years)}
                            formik={formik}
                          />

                          <MultipleSelect
                            label="Dificuldade"
                            name="difficulties"
                            options={sortArray(filterOptions.difficulties)}
                            formik={formik}
                          />
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Flex>
              </S.Form>
            </S.Container>

            {questions && questions.length > 0 && (
              <>
                {questionsToShow?.map((question) => (
                  <S.ListItem
                    key={question.id}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex
                      direction="column"
                      gap={8}
                      style={{ flexWrap: "wrap" }}
                    >
                      <Flex
                        gap={8}
                        alignItems="center"
                        style={{ flexWrap: "wrap" }}
                      >
                        <Checkbox
                          name={String(question.id)}
                          checked={formik.values.selectedQuestions.includes(
                            question.id
                          )}
                          onChange={() => handleCheckboxChange(question.id)}
                        />
                        <Typography size={14} weight={600}>
                          {capitalizeWords(question.course)}
                        </Typography>
                        |
                        <Typography size={14}>
                          {capitalizeWords(question.matter)}
                        </Typography>
                        |<Typography size={14}>{question.bank}</Typography>|
                        <Typography size={14}>{question.year}</Typography>|
                        <Typography size={14}>{question.difficulty}</Typography>
                      </Flex>

                      <div>
                        <QuestionImage src={question.description_issue} />
                      </div>

                      <Flex direction="column" gap={4}>
                        <Typography size={12}>
                          {question.alternative_a}
                        </Typography>
                        <Typography size={12}>
                          {question.alternative_b}
                        </Typography>
                        <Typography size={12}>
                          {question.alternative_c}
                        </Typography>
                        <Typography size={12}>
                          {question.alternative_d}
                        </Typography>
                        <Typography size={12}>
                          {question.alternative_e}
                        </Typography>
                      </Flex>
                    </Flex>
                  </S.ListItem>
                ))}

                <Flex width="100%" justifyContent="flex-end">
                  <Pagination
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalItems={filteredQuestions?.length ?? questions.length}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
    </PageTemplate>
  );
};
