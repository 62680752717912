import { ERoutesPath } from "./routesPath";
import {
  HomePage,
  QuestionsPage,
  ProfessionalPage,
  QuestionManagement,
  SubjectsStudiedPage,
  ClientsPage,
  AdminSimulatePage,
  RevisionsPage,
  RevisionQuestionsPage,
  RevisionGeneralPage,
  CorrectQuestionsPage,
  FlashCardPage,
  SimulateMainPage,
  SimulateNewPage,
  SimulateAnswerPage,
  SimulateResumePage,
  AdminCreateSimulatePage,
} from "../pages";

export const ROUTES = [
  { path: ERoutesPath.ROOT, element: HomePage },
  { path: ERoutesPath.INICIO_PROFISSIONAL, element: ProfessionalPage },
  { path: ERoutesPath.ADMINISTRAR_QUESTOES, element: QuestionManagement },
  { path: ERoutesPath.ADMINISTRAR_USUARIOS, element: ClientsPage },
  { path: ERoutesPath.ADMINISTRAR_SIMULADOS, element: AdminSimulatePage },
  {
    path: ERoutesPath.ADMINISTRAR_SIMULADOS_NOVO,
    element: AdminCreateSimulatePage,
  },

  { path: ERoutesPath.QUESTOES, element: QuestionsPage },
  { path: ERoutesPath.ASSUNTOS_ESTUDADOS, element: SubjectsStudiedPage },
  { path: ERoutesPath.REVISOES, element: RevisionsPage },
  { path: ERoutesPath.REVISAO, element: RevisionQuestionsPage },
  { path: ERoutesPath.REVISAO_GERAL, element: RevisionGeneralPage },
  { path: ERoutesPath.CORRECAO, element: CorrectQuestionsPage },

  { path: ERoutesPath.REVISE_RAPIDO, element: FlashCardPage },
  { path: ERoutesPath.SIMULADOS, element: SimulateMainPage },
  { path: ERoutesPath.SIMULADOS_NOVO, element: SimulateNewPage },
  { path: ERoutesPath.SIMULADO_RESPONDER, element: SimulateAnswerPage },
  { path: ERoutesPath.SIMULADO_RESUMO, element: SimulateResumePage },
];
