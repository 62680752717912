export const PLAN_VALUES = [
  {
    title: "PLANO SEMESTRAL",
    itens: [
      "Plataforma de questões resolvidas;",
      "Revise Rápido (verdadeiro ou falso);",
      "Benefício do usuário durante período contratado (novas funcionalidades da plataforma, novos materiais/questões e outros).",
    ],
    link: "https://clkdmg.site/pay/plataforma-revise-semestral",
    price: "R$ 899,00",
  },
  {
    title: "PLANO ANUAL",
    itens: [
      "Plataforma de questões resolvidas;",
      "Revise Rápido (verdadeiro ou falso);",
      "Benefício do usuário durante período contratado (novas funcionalidades da plataforma, novos materiais/questões e outros).",
    ],
    link: "https://clkdmg.site/pay/plataforma-revise-anual-vr",
    price: "R$ 1.299,00",
  },
];
