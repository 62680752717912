import styled from "styled-components";
import { Flex } from "../../../components";

export const ListItem = styled(Flex)`
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const Form = styled.form`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
