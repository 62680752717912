import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import paper from "../../../assets/paper.svg";
import people from "../../../assets/people.svg";
import edit from "../../../assets/edit.png";
import adjusts from "../../../assets/ajustes.png";
import simulate from "../../../assets/simulate.png";

import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";
import { QuestionContext } from "../../../contexts/question";
import { Flex, PageTemplate, Typography } from "../../../components";

import * as S from "./styles";
import { Card } from "./Card";

export const ProfessionalPage: React.FC = () => {
  const navigate = useNavigate();
  const { typeUser } = useContext(AuthContext);
  const { setSelectedQuestion } = useContext(QuestionContext);

  useEffect(() => {
    if (typeUser !== "10") {
      navigate(ERoutesPath.ROOT);
      return;
    }
  }, []);

  return (
    <PageTemplate title="O que você precisa fazer?">
      <Flex direction="column" gap={16}>
        <Typography color="#78909d" family="Montserrat" size={16}>
          Escolha uma das opções abaixo para acessar um dos serviços da Revise.
        </Typography>

        <S.CardsContainer gap={32} justifyContent="center">
          <Card
            title="Cadastrar questões"
            subtitle="Realizar cadastro de novas questões."
            imageSrc={paper}
            onClick={() => {
              setSelectedQuestion(undefined);
              navigate(ERoutesPath.ADMINISTRAR_QUESTOES);
            }}
          />

          <Card
            title="Atualizar questões"
            subtitle="Edite as questões já cadastradas."
            imageSrc={edit}
            onClick={() => navigate(ERoutesPath.QUESTOES)}
          />

          <Card
            title="Usuários"
            subtitle="Veja e gerencie a lista de todos os seus usuários."
            imageSrc={people}
            onClick={() => navigate(ERoutesPath.ADMINISTRAR_USUARIOS)}
          />

          <Card
            title="Relatórios de correções"
            subtitle="Veja a lista de solicitações de correções das questões."
            imageSrc={adjusts}
            onClick={() => navigate(ERoutesPath.CORRECAO)}
          />

          <Card
            title="Gerador de simulados"
            subtitle="Gere e atribua simulados para os usuários"
            imageSrc={simulate}
            onClick={() => navigate(ERoutesPath.ADMINISTRAR_SIMULADOS)}
          />
        </S.CardsContainer>
      </Flex>
    </PageTemplate>
  );
};
