import { Typography } from "../../../components";
import { TCardProps } from "./types";
import * as S from "./styles";

export const Card = ({ title, subtitle, imageSrc, onClick }: TCardProps) => {
  return (
    <S.CardContentContainer
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
    >
      <Typography
        size={14}
        family="Montserrat"
        align="center"
        weight={900}
        color="#3A66FB"
      >
        {title}
      </Typography>

      <img src={imageSrc} style={{ width: 80, height: 80 }} />

      <Typography
        size={12}
        family="Montserrat"
        align="center"
        weight={500}
        color="#1A1A1A"
      >
        {subtitle}
      </Typography>
    </S.CardContentContainer>
  );
};
