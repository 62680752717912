import styled from "styled-components";
import { Flex } from "../../../components";

export const CardsContainer = styled(Flex)`
  flex-wrap: wrap;
`;

export const CardContentContainer = styled(Flex)`
  padding: 16px;
  width: 184px;
  height: 184px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #a9bdff;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px rgba(26, 26, 26, 0.2);
  }
`;
