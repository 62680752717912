import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  InputText,
  MultipleSelect,
  PageTemplate,
  ReviseLoading,
  Typography,
} from "../../../components";
import { timeOption } from "../../../components/organisms/QuestionFilter/helper";
import { toast } from "react-toastify";
import { ERoutesPath } from "../../../routes";

import api, { END_POINTS } from "../../../services/api";
import { useFormik } from "formik";

import { TAdminAssignSimulateProps, TDataForm, TUser } from "./types";
import * as S from "./styles";

export const AdminAssignSimulate = ({
  simulateData,
  questionsData,
  changeStep,
}: TAdminAssignSimulateProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [usersData, setUsersData] = useState<Array<TUser>>();

  const admSimulateAssignBreadcrumb = [
    { label: "Painel administrativo", route: ERoutesPath.INICIO_PROFISSIONAL },
    {
      label: "Simulados",
      route: ERoutesPath.ADMINISTRAR_SIMULADOS,
      onClick: changeStep,
    },
    {
      label: "Atribuir",
    },
  ];

  useEffect(() => {
    const handleUsers = async () => {
      try {
        const users = await api.get<Array<TUser>>(END_POINTS.usersList);

        const filtered = users.data.filter((user) => user.paid);
        setUsersData(filtered);
      } finally {
        setIsLoading(false);
      }
    };
    if (!questionsData || !simulateData) {
      changeStep();
    }

    handleUsers();
  }, []);

  const initialValues: TDataForm = {
    name: simulateData?.name || "",
    time: "1h",
    filter: "",
    selectedUsers: [] as number[],
  };

  const handleSubmit = async (dataForm: TDataForm) => {
    try {
      const body = {
        name: dataForm.name,
        ids_accounts: dataForm.selectedUsers,
        time: dataForm.time,
        ids_questions: questionsData,
      };

      await api.post(END_POINTS.createBatchSimulates, body);
      toast.success(
        `Simulado criado, para ${dataForm.selectedUsers.length} usuário(s), com sucesso`
      );
      changeStep();
    } catch {
      toast.error("Falha ao atribuir simulados");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => handleSubmit(data),
  });

  const handleCheckboxChange = (userId: number) => {
    const { selectedUsers } = formik.values;
    if (selectedUsers.includes(userId)) {
      formik.setFieldValue(
        "selectedUsers",
        selectedUsers.filter((id) => id !== userId)
      );
    } else {
      formik.setFieldValue("selectedUsers", [...selectedUsers, userId]);
    }
  };

  const filteredUsers = usersData?.filter((user) =>
    [user.name, user.email, user.cellphone].some((field) =>
      field.toLowerCase().includes(formik.values.filter.toLowerCase())
    )
  );

  const deselectAllUsers = () => {
    formik.setFieldValue("selectedUsers", []);
  };

  const hasSelectedUsers = () => formik.values.selectedUsers.length > 0;

  return (
    <PageTemplate
      title="Administração - atribuir simulados"
      breadcrumbsItems={admSimulateAssignBreadcrumb}
    >
      <Flex direction="column" gap={16}>
        <S.Container>
          <S.Form onSubmit={formik.handleSubmit} noValidate>
            <Flex gap={16} width="100%">
              <InputText
                label="Nome do simulado"
                name="name"
                formik={formik}
                minLength={3}
                maxLength={30}
              />

              <MultipleSelect
                label="Tempo"
                name="time"
                options={timeOption}
                formik={formik}
                acceptOne
              />
            </Flex>

            <Flex justifyContent="space-between">
              <Typography size={14} color="#607D8B">
                {formik.values.selectedUsers.length} usuário(s) selecionado(s)
              </Typography>
              <Button size="small" disabled={!hasSelectedUsers()}>
                Atribuir
              </Button>
            </Flex>
          </S.Form>
        </S.Container>

        <S.Container style={{ padding: "16px" }}>
          <Typography size={24}>Usuários</Typography>
          <Flex direction="column" gap={8} style={{ maxWidth: "300px" }}>
            <InputText
              label="Pesquisar: nome, email ou telefone"
              name="filter"
              formik={formik}
              minLength={3}
              maxLength={30}
            />

            <Button size="small" onClick={deselectAllUsers} isSecondary>
              Desmarcar Todos
            </Button>
          </Flex>
        </S.Container>

        <Flex direction="column" width="100%" gap={8}>
          {isLoading ? (
            <ReviseLoading />
          ) : (
            <>
              {filteredUsers?.map((user) => (
                <S.ListItem
                  key={user.id}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex
                    gap={8}
                    alignItems="center"
                    style={{ flexWrap: "wrap" }}
                  >
                    <Checkbox
                      name={String(user.id)}
                      checked={formik.values.selectedUsers.includes(user.id)}
                      onChange={() => handleCheckboxChange(user.id)}
                    />
                    <Typography size={14} weight={600}>
                      {user.name}
                    </Typography>
                    <Typography size={14} color="#607D8B">
                      {user.email}
                    </Typography>
                    <Typography size={14} color="#607D8B">
                      {user.cellphone}
                    </Typography>
                  </Flex>

                  <Flex>
                    <Typography
                      size={10}
                      color={user.paid ? "#029198" : "#f18a8a"}
                    >
                      {user.paid ? "Assinante" : "Não assinante"}
                    </Typography>
                  </Flex>
                </S.ListItem>
              ))}
            </>
          )}
        </Flex>
      </Flex>
    </PageTemplate>
  );
};
