import { forwardRef, useContext, useState } from "react";

import {
  Flex,
  EditQuestion,
  Button,
  ReportQuestionModal,
  QuestionNumber,
} from "../../../components";

import { eraserIcon, scissorsIcon } from "../../../assets";
import api, { END_POINTS } from "../../../services/api";

import { QuestionImage, Feedback } from "./helpers";
import { TQuestionProps } from "./types";
import { AuthContext } from "../../../contexts/auth";
import * as S from "./styles";

export const Question = forwardRef<HTMLDivElement, TQuestionProps>(
  (
    {
      data,
      number,
      allowRedo = false,
      isPreviousAnswerCorrect,
      hideAction = false,
      onSelectQuestion,
      previewsSelectedAnswered,
      enableFeedback = false,
      hideAlternatives = false,
    },
    ref
  ) => {
    const [selected, setSelected] = useState<string>(
      previewsSelectedAnswered ?? ""
    );
    const [strikeQuestions, setStrikeQuestions] = useState<Array<string>>([]);
    const [showFeedback, setShowFeedback] = useState<boolean>(enableFeedback);
    const [reportQuestion, setReportQuestion] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { user } = useContext(AuthContext);
    const idAccount = user.id_user;

    const getAlternatives = (question: any) => {
      return ["a", "b", "c", "d", "e"].map((letter) => ({
        value: question[`alternative_${letter}`].replace(/^[ABCDE]\)\s*/, ""),
        option: letter.toUpperCase(),
      }));
    };

    const handleSelect = (questionOption: string) => {
      setSelected(questionOption);

      if (onSelectQuestion) {
        onSelectQuestion(questionOption, "ADD");
      }
    };

    const handleStrikeOption = (letter: string) => {
      if (selected === letter) {
        setSelected("");
        if (onSelectQuestion) {
          onSelectQuestion(letter, "REMOVE");
        }
      }

      setStrikeQuestions((prevStrikeQuestions) => {
        if (prevStrikeQuestions.includes(letter)) {
          return prevStrikeQuestions.filter((item) => item !== letter);
        } else {
          return [...prevStrikeQuestions, letter];
        }
      });
    };

    const handleResponseQuestion = async (
      questionID: number,
      option: string
    ) => {
      try {
        setIsLoading(true);
        const body = {
          id_question: questionID,
          id_account: idAccount,
          feedback: option.toUpperCase(),
        };

        await api.post(END_POINTS.responseQuestion, body);
      } catch (err) {
        console.warn(err);
      } finally {
        setIsLoading(false);
        setShowFeedback(true);
      }
    };

    const handleResend = () => {
      setSelected("");
      setShowFeedback(false);
      setStrikeQuestions([]);
    };

    const handleReport = () => {
      setReportQuestion(true);
    };

    return (
      <S.Container direction="column" gap={16} width="100%" ref={ref}>
        <div>
          <EditQuestion question={data} />

          <Flex width="100%" gap={4}>
            <QuestionNumber
              number={number}
              isCorrect={isPreviousAnswerCorrect}
            />
            <div>
              <QuestionImage src={data.description_issue} />
              <QuestionImage src={data.alternatives_image} />
            </div>
          </Flex>
        </div>

        {!hideAlternatives && (
          <Flex direction="column" gap={4}>
            {getAlternatives(data).map(({ value, option }) => {
              const getIsCorrect = (
                showFeedback: boolean,
                option: string,
                feedback: string
              ) => {
                if (!showFeedback) return undefined;
                return feedback === option;
              };

              return (
                <S.AlternativeWrapper
                  alignItems="center"
                  gap={4}
                  key={option}
                  isDisabled={showFeedback}
                >
                  <S.AlternativeAction
                    width="30px"
                    height="30px"
                    justifyContent="center"
                    alignItems="center"
                    onClick={
                      showFeedback
                        ? undefined
                        : () => handleStrikeOption(option)
                    }
                  >
                    <img
                      src={
                        strikeQuestions.includes(option)
                          ? eraserIcon
                          : scissorsIcon
                      }
                    />
                  </S.AlternativeAction>

                  <S.Alternative
                    alignItems="center"
                    gap={16}
                    width="fit-content"
                    isSelected={selected === option}
                    isCorrect={getIsCorrect(
                      showFeedback,
                      option,
                      data.feedback
                    )}
                    isDisabled={
                      strikeQuestions.includes(option) || showFeedback
                    }
                    onClick={
                      strikeQuestions.includes(option) || showFeedback
                        ? undefined
                        : () => handleSelect(option)
                    }
                  >
                    <div>
                      <S.Letter
                        width="30px"
                        height="30px"
                        justifyContent="center"
                        alignItems="center"
                        letter={option}
                      />
                    </div>
                    <S.Label isStrike={strikeQuestions.includes(option)}>
                      {value}
                    </S.Label>
                  </S.Alternative>
                </S.AlternativeWrapper>
              );
            })}
          </Flex>
        )}

        <Flex gap={8}>
          {!showFeedback && !hideAction && (
            <Button
              size="small"
              onClick={() => handleResponseQuestion(data.id, selected)}
              disabled={!selected}
              isLoading={isLoading}
            >
              Confirmar
            </Button>
          )}

          {allowRedo && showFeedback && (
            <Button size="small" isSecondary onClick={handleResend}>
              Responder novamente
            </Button>
          )}
        </Flex>

        {showFeedback && (
          <Feedback
            isCorrect={selected === data.feedback}
            question={data}
            onHandleReport={handleReport}
          />
        )}

        {reportQuestion && (
          <ReportQuestionModal
            modalDismiss={() => {
              setReportQuestion(false);
            }}
            idQuestion={String(data.id)}
            idAccount={idAccount}
          />
        )}
      </S.Container>
    );
  }
);

Question.displayName = "Question";
