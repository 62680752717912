export const END_POINTS = {
  /**
   * @deprecated use from filterQuestionsByBank instead
   */
  filterQuestions: "api/filtersQuestions",
  filterQuestionsByBank: "api/filtersQuestionsByBank",

  flashcards: "api/flashcards",

  lastQuestionsAnswered: "api/lastQuestionsAnswered",
  responseQuestion: "api/responseQuestion",
  questionByFilter: "api/questionsByFilter",
  coursesByQuestion: "api/coursesByQuestion",
  /**
   * @deprecated use from studiesSubjectsByUser instead
   */
  studiesListByUser: "api/studied/list/student",
  studiesSubjectsByUser: "/api/studied/subjects",

  addStudiedSubject: "api/studied/add/subjects",
  removeStudiedSubject: "api/studied/delete/subjects",

  login: "login",
  logout: "logout",

  revisionByUser: "/api/student/revision",

  // Simulados
  simulates: "/api/simulates",
  simulateQuestions: "/api/simulates/questions",
  simulateChangeStatus: "api/simulates/changeStatus",
  simulateResponseQuestion: "/api/simulates/responseQuestion",
  simulateChangePinned: "/api/simulates/change-pinned",

  // Revisões
  validateReview: "api/student/validate/review/",
  revisionsReviewed: "api/student/revisions-reviewed",

  // Administração
  userAccount: "api/user/account",
  usersList: "api/accounts",
  createBatchSimulates: "api/simulates/admin",
};
