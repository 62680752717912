export const capitalizeWords = (input: string) =>
  input
    .toLowerCase()
    .replace(/(^|\s)([a-zA-Z\u00C0-\u017F])/g, (match) => match.toUpperCase());

export const toUpperCase = (
  input: string | Array<string>
): string | Array<string> => {
  if (typeof input === "string") {
    return input.toUpperCase();
  }

  return input.map((item) => item.toUpperCase());
};

export const sortArray = (array: string[]): string[] => {
  return array.sort((a, b) =>
    a.localeCompare(b, "pt", { sensitivity: "base" })
  );
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const normalizeString = (str: string): string => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
};
