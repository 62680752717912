import styled from "styled-components";

export const Container = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  width: 100%;
  height: 100%;
  padding: ${({ isModal }) => (isModal ? "unset" : "40px 60px")};

  background-color: #ffffff;
  @media (max-width: 800px) {
    padding: ${({ isModal }) => (isModal ? "unset" : "40px")};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
  max-width: 800px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Title = styled.h2<{ isBigger?: boolean }>`
  font-size: ${({ isBigger }) => (isBigger ? "40px" : "24px")};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  min-height: 56px;
  color: #3a66fb;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;

  @media (max-width: 800px) {
    font-size: ${({ isBigger }) => (isBigger ? "32px" : "24px")};
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  width: 100%;
  padding-bottom: 25px;

  max-width: 400px;
`;

export const PlanTitle = styled.div`
  background-color: #3a66fb;
  border-radius: 8px 8px 0 0;
`;

export const List = styled.li`
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;

  :before {
    content: "✔";
    position: absolute;
    left: 0;
    color: "#000000";
    font-size: 16px;
  }
`;
