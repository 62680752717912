import { TSimulate } from "../../Simulate/types";

export enum EStep {
  LIST = "LIST",
  ASSIGN = "ASSIGN",
}

export type TAdminAssignSimulateProps = {
  simulateData?: TSimulate;
  questionsData?: Array<number>;
  changeStep: () => void;
};

export type TUser = {
  id: number;
  name: string;
  email: string;
  cellphone: string;
  paid: boolean;
};

export type TDataForm = {
  name: string;
  time: string;
  filter: string;
  selectedUsers: Array<number>;
};
